<template>
  <div class="Equipment">
    <block_a v-if="Tclass=='设备维保'"></block_a>
    <block_b v-if="Tclass=='预估报价'"></block_b>
    <block_c v-if="Tclass=='精准报价'"></block_c>
    <div class="bottom">
      <div class="block" @click="upClass('设备维保')">
        <div class="a">
          <img src="../../assets/image/a.png" alt="">
          <img src="../../assets/image/a-plus.png" alt="" v-if="Tclass=='设备维保'">
        </div>
        <div class="b" v-bind:class="{Tclass:Tclass=='设备维保'}">设备维保</div>
      </div>
      <div class="block" @click="upClass('预估报价')">
        <div class="a">
          <img src="../../assets/image/b.png" alt="">
          <img src="../../assets/image/b-plus.png" alt="" v-if="Tclass=='预估报价'">
        </div>
        <div class="b" v-bind:class="{Tclass:Tclass=='预估报价'}">预估报价</div>
      </div>
      <div class="block" @click="upClass('精准报价')">
        <div class="a">
          <img src="../../assets/image/c.png" alt="">
          <img src="../../assets/image/c-plus.png" alt="" v-if="Tclass=='精准报价'">
        </div>
        <div class="b" v-bind:class="{Tclass:Tclass=='精准报价'}">精准报价</div>
      </div>
    </div>
  </div>
</template>
<script>
import block_a from './com/block_a.vue'
import block_b from './com/block_b.vue'
import block_c from './com/block_c.vue'
import {Area} from "vant";

export default {
  data() {
    return {
      Tclass: '设备维保'
    }
  },
  components: {block_a, block_b, block_c, [Area.name]: Area},
  mounted() {

  },
  methods: {
    upClass(value) {
      this.Tclass = value
    }
  }
}
</script>

<style scoped lang="scss">
.Equipment {
  width: 100%;
  height: 100%;
  background-color: #fbfbfb;
}


.bottom {
  width: 100%;
  height: 70px;
  background-color: #FFFFFF;
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;

  .block {
    width: 100px;
    height: 100%;
    position: relative;

    .a {
      width: 22px;
      height: 22px;
      position: absolute;
      left: 50%;
      margin-left: -15px;
      top: 15px;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 3px;
        top: 0;
      }
    }

    .b {
      position: absolute;
      top: 43px;
      width: 100%;
      text-align: center;
      font-size: 11px;
      color: #767676;
      font-weight: bold;
    }
  }
}

.Tclass {
  color: #0256ff !important;
}
</style>