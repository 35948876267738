<template>
  <div class="root_main">
    <div class="top">预估报价</div>
    <block_1></block_1>
    <div class="in"></div>

  </div>
</template>

<script>
import block_1 from '../../Home/com/block_1.vue'

export default {
  data() {
    return {}
  },
  methods: {},
  components: {block_1}
}
</script>

<style scoped lang="scss">
.root_main {
  width: 100%;
  background-color: #dae2ff;
  background-image: url('https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2024-4-29/bg.png');
  background-size: 100%;
  box-sizing: border-box;
}

.top {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #535353;
  font-size: 15px;
  font-weight: bold;
  background-color: #FFFFFF;
  position: relative;
}

.in {
  width: 100%;
  height: 200px;
}
</style>