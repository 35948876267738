<template>
  <div class="root_main">
    <div class="top">设备维保</div>
    <div class="main">
      <div class="in"></div>
      <div class="block">
        <p class="text">设备编号：{{ Equipment_id }}</p>
        <p class="text">设备昵称：{{ data.Name }}</p>
        <p class="text">业务员：{{ data.BusinessName }}</p>
        <p class="text">业务员联系方式：{{ data.BusinessPhone }}</p>
        <p class="text">创建日期：{{ data.NewTime }}</p>
      </div>
      <textarea v-model="Text" placeholder="请输入设备问题描述"></textarea>
      <div class="uploaderMain">
        <div class="img" v-for="(item,index) in contentImage" :key="index"><img :src="item" alt=""
                                                                                style="width: 100%;height: 100%"></div>
        <van-uploader :after-read="afterRead">
          <img src="../../../assets/image/uploader.png" style="width: 80px;height: 80px" alt="">
        </van-uploader>
      </div>
    </div>

    <div class="button" @click="setdata">提交</div>
    <div class="in"></div>
    <div class="LoadMain" v-if="LoadMain">
      <div class="text">
        <p>上传中</p>
        <p>{{ LoadTage }}</p>
        <div class="link" :style="{width:LoadTage}"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {add_a, findequipment} from "@/api/Equipment_a";

const Aliyun = {
  accessKeyId: "LTAI4GGraGrb3vc6sDGXMSya",
  accessKeySecret: 'TVmlizrLbGrQdt5jgQj3UyabXIKjZd',
  bucket: '3qlab-wrth',
  region: 'oss-cn-hangzhou',
  url: 'https://3qlab-wrth.oss-cn-hangzhou.aliyuncs.com/'
}
//阿里云SDK
const OSS = require('ali-oss');
const client = OSS({
  accessKeyId: Aliyun.accessKeyId,
  accessKeySecret: Aliyun.accessKeySecret,
  bucket: Aliyun.bucket,
  region: Aliyun.region,
  secure: true
});


import {Uploader, Notify, Dialog} from "vant";

export default {
  data() {
    return {
      Text: '',
      LoadMain: '',
      LoadTage: '',
      contentImage: [],
      Equipment_id: null,
      data: {}
    }
  },
  mounted() {
    let Equipment_id = localStorage.getItem('Equipment_id')
    this.Equipment_id = Equipment_id
    this.init()
  },
  methods: {
    init() {
      let Equipment_id = this.Equipment_id

      findequipment({Identifier: Equipment_id}).then(res => {
        let data = res.payload
        let time = data.NewTime
        let day = new Date(time);
        data.NewTime = day.getFullYear() + '-' + (day.getMonth() + 1) + '-' + day.getDate()

        this.data = data

        let Business_id = data.Business_id
        localStorage.setItem("Business_id", Business_id);
      })
    },
    exit() {
      Dialog.confirm({
        message: '是否退出当前的账号？',
      }).then(() => {
        localStorage.removeItem('user')
        this.$router.push(
            {
              path: '/'
            }
        )
      }).catch(() => {
      });
    },
    setdata() {
      let Equipment_id = this.Equipment_id
      let ImageData = this.contentImage
      let Text = this.Text

      if (ImageData.length >= 1 && Text) {
        add_a({ImageData, Equipment_id, Text}).then(res => {
          if (res.errorCode == 200) {
            Notify({type: 'success', message: '提交成功'});
            this.closeData()
          } else {
            Notify({type: 'warning', message: res.errorMsg});
          }
        })
      } else {
        Notify({type: 'warning', message: '缺少必填项'});
      }
    },
    closeData() {
      this.contentImage = []
      this.Text = ''
    },
    //上传图片
    async afterRead(value) {
      let that = this
      let fileData = value.file
      let ImgTime = this.ImgTime()
      let filename = this.ImgTimestamp()
      this.LoadMain = true
      this.LoadTage = '0%'
      let result = await client.multipartUpload(`image/${ImgTime}/${filename}.jpg`, fileData, {
        progress(e) {
          that.Tprogress(e)
        }
      });
      let res = result.res

      if (res.status == 200 && res.statusCode == 200) {
        this.LoadMain = false
        let requestUrls = this.removeURLParams(res.requestUrls[0])
        this.contentImage.push(requestUrls)
        console.log(this.contentImage)
        Notify({type: 'success', message: '上传成功'});
      } else {
        this.LoadMain = false
        Notify({type: 'warning', message: '上传失败'});
      }
    },
    removeURLParams(url) {
      let routeData = url.split("?")[0];
      return routeData
    },
    Tprogress(p) {
      let percentage = (p * 100).toFixed(2) + "%";
      this.LoadTage = percentage
    },
    ImgTimestamp() {
      var timestamp = Date.now(); // 获取当前时间戳（单位为毫秒）
      return timestamp
    },
    ImgTime() {
      let Year = new Date().getFullYear();  //年份
      let Month = new Date().getMonth() + 1;  //月份
      let Day = new Date().getDate();  //天
      let time = `${Year}-${Month}-${Day}`;

      return time;
    }
  },
  components: {[Uploader.name]: Uploader,}
}
</script>

<style lang="scss" scoped>
.root_main {
  width: 100%;
  height: 800px;
  background-color: #dae2ff;
  background-image: url('https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2024-4-29/bg.png');
  background-size: 100%;
  box-sizing: border-box;
  position: relative;
}

.top {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #535353;
  font-size: 15px;
  font-weight: bold;
  background-color: #FFFFFF;
  position: relative;

  .exit {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 20px;
    top: 20px;
  }
}

.infos {
  width: 95%;
  height: 40px;
  background-color: #FFFFFF;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 10px;

  input {
    display: block;
    width: 90%;
    height: 40px;
    border: none;
    background: none;
    text-indent: 1rem;
    border-radius: 5px;
    margin: 0 auto;
  }

  input:focus {
    outline: none;
    border: none;
  }
}

.info {
  width: 95%;
  height: 160px;
  background-color: #FFFFFF;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 10px;

  .title {
    width: 100%;
    text-indent: 1rem;
    color: #FFFFFF;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    background-image: url("../../../assets/image/title_1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-weight: bold;
  }

  input {
    display: block;
    width: 80%;
    height: 30px;
    border: none;
    background: none;
    text-indent: 1rem;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 20px;
    background-color: #f4f4f4;
    color: #b5b5b5;
    border-radius: 10px;
    //color: #dcdcdc;
  }

  input:focus {
    outline: none;
    //border: none;
  }
}

.main {
  width: 95%;
  height: 600px;
  background-color: #FFFFFF;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 10px;

  .in {
    width: 100%;
    height: 15px;
  }

  .block {
    height: 180px;
    width: 90%;
    margin: 0 auto;
    font-size: 15px;
    font-family: 'PuHuiTi';
    position: relative;
  }

  textarea {
    display: block;
    width: 80%;
    height: 200px;
    margin: 0 auto;
    background-color: #f4f4f4;
    color: #b5b5b5;
    border: none;
    padding: 20px 20px;
    border-radius: 10px;
  }

  textarea:focus {
    outline: none;
    //border: none;
  }

  .uploaderMain {
    width: 90%;
    height: 90px;
    margin: 0 auto;
    margin-top: 20px;
    overflow-x: auto;
    white-space: nowrap; /* 确保块状元素在同一行显示 */
    .img {
      width: 80px;
      height: 80px;
      display: inline-block;
      margin-right: 10px;
      //float: left;
    }

    .uploader {
      width: 80px;
      height: 80px;
      display: inline-block;
      float: left;
    }
  }
}

.button {
  width: 200px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  background-color: #3862ff;
  color: #ffffff;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  margin-left:-100px;
  top: 720px;
}

.in {
  width: 100%;
  height: 200px;
  background-color: #dae2ff;
  background-image: url('https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2024-4-29/bg.png');
  background-size: 100%;
}

.LoadMain {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);

  .text {
    position: absolute;
    top: 50%;
    margin-top: -44px;
    color: #fff;
    width: 100%;
    height: 88px;
    text-align: center;
    line-height: 25px;
    font-size: 14px;

    .link {
      background-color: #1989fa;
      height: 10px;
    }
  }
}
</style>