<template>
  <div class="root_main">
    <div class="top">精准报价</div>
    <div class="coverImage"></div>
    <div class="info">
      <div class="title">基础信息</div>
      <input placeholder="请输入姓名" v-model="UserName"/>
      <input placeholder="请输入公司名称" v-model="CompanyName"/>
      <input placeholder="请输入联系方式" v-model="UserPhone"/>
      <div class="text">提交后，我们将会在24小时内与您联系<br>请注意接听电话</div>
    </div>
    <div class="button" @click="setdata">提交</div>
  </div>
</template>

<script>
import {add_b} from "@/api/Equipment_a";
import {Dialog, Notify} from "vant";

export default {
  data() {
    return {
      UserName: '',
      CompanyName: '',
      UserPhone: '',
    }
  },
  methods: {
    exit(){
      Dialog.confirm({
        message: '是否退出当前的账号？',
      }).then(() => {
        localStorage.removeItem('user')
        this.$router.push(
            {
              path: '/'
            }
        )
      }).catch(() => {
      });
    },
    setdata() {
      let Business_id = localStorage.getItem('Business_id')
      let CompanyName = this.CompanyName
      let UserPhone = this.UserPhone
      let UserName = this.UserName

      if (/^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/.test(this.UserPhone)) {
        add_b({Business_id, CompanyName, UserPhone, UserName}).then(res => {
          if (res.errorCode == 200) {
            Notify({type: 'success', message: '提交成功'});
            this.closeData()
          } else {
            Notify({type: 'warning', message: res.errorMsg});
          }
        })
      }else {
        Dialog.alert({
          message: '手机号码不符合规则',
        });
      }



    },
    closeData() {
      this.CompanyName = ''
      this.UserPhone = ''
      this.UserName = ''
    }
  }
}
</script>

<style scoped lang="scss">
.root_main {
  width: 100%;
  height: 100%;
  background-color: #dae2ff;
  background-image: url('https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2024-4-29/bg.png');
  background-size: 100%;
  box-sizing: border-box;
}

.top {
  width: 100%;
  height: 60px;
  line-height: 60px;
  text-align: center;
  color: #535353;
  font-size: 15px;
  font-weight: bold;
  background-color: #FFFFFF;
  position: relative;
}

.coverImage {
  width: 100%;
  height: 200px;
  background-image: url("https://hs-hospital.oss-cn-hangzhou.aliyuncs.com/image/2023-6-10/block_c_1.png");
  background-size: 100% 100%;
}

.info {
  width: 95%;
  height: 300px;
  background-color: #FFFFFF;
  margin: 0 auto;
  margin-top: 20px;
  border-radius: 10px;

  .title {
    width: 100%;
    text-indent: 1rem;
    color: #FFFFFF;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    background-image: url("../../../assets/image/title_1.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    font-weight: bold;
  }

  input {
    display: block;
    width: 80%;
    height: 30px;
    border: 1px solid #ededed;
    background: none;
    text-indent: 1rem;
    border-radius: 5px;
    margin: 0 auto;
    margin-top: 20px;
    font-size: 14px;
    background-color: #f4f4f4;
    color: #b5b5b5;
    //color: #dcdcdc;
  }

  input:focus {
    outline: none;
    //border: none;
  }

  .text {
    color: #c2c2c2;
    width: 90%;
    margin: 0 auto;
    font-size: 14px;
    text-align: center;
    margin-top: 30px;
  }
}

.button {
  width: 80%;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;
  background-color: #3862ff;
  color: #ffffff;
  border-radius: 10px;
}
</style>